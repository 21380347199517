import React, { useState, useEffect } from "react" ;
import emailjs from "emailjs-com";
import tw from "twin.macro";
import styled from "styled-components";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons2.js";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Checkbox } from "@mui/material";

const TextContent = tw.div`lg:py-8 text-center md:text-left`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`
const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-lg md:mx-0`
const Input = tw.input`mt-6 w-64 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const InputWide = tw.input`mt-6 w-128 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(InputWide).attrs({as: "textarea"})`
  ${tw`h-20`}
`
const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default function CartEmailForm(parentCart) {  
  const description = "We are currently not processing online payments";
  const description2 = "Please send us your order and pay with Venmo (@Noreen-Wilson-1 (last 4 digits: 1989)) or Zelle (7703644499)";
  const submitButtonText = "Send Order";
  const formAction = "#";
  const formMethod = "get";
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  var loopCartData = parentCart.parentCart;
  var forEachTitle = 'Requested Items: ';
  loopCartData.forEach(d => forEachTitle += `${d.title} (${d.qty}), `);
  
  const initialTotal = 0;
  const CartTotal = loopCartData.reduce((accumulator,d) => accumulator + d.price * d.qty, initialTotal)

  var orderSummary = forEachTitle.slice(0, -2);
  var InitialValues = {
    message: { orderSummary },
    subject: "Noreen's Sourdough To Go Order"
  };

  const EmailDetailText = "Total: $" + CartTotal + "  -  " + InitialValues.message.orderSummary
  
  const refreshPage = () => {
    parentCart = [];
    setTimeout(()=>{
      window.location.reload(false);
    }, 500);
    navigate("/thank-you");
   }

   const handleChange = event => {
    if (event.target.checked){
      event.target.value = 'YES';
    }else {
      event.target.value = 'NO';
    };
  };

  function sendEmail(e) {
    e.preventDefault();

  emailjs.sendForm('service_qplzjbr', 'template_qgfv101', e.target, '5P9S3_QkGHMU5Yjsi')
    .then((result) => {
        console.log(result.text);
        localStorage.setItem('cart',JSON.stringify([]));
        setShow(true);
        refreshPage();
    }, (error) => {
        console.log(error.text);
        setShow(false);
    });
    e.target.reset();
    alert('Order Data Sent Successfully')
  }
  return (
         <TextContent style={{paddingTop: 0 }}>
          {show ? (  
            <div>
            <span style={{ fontWeight: 700, fontSize: 25, color: "Blue", backgroundColor: "white" }}>
              Order details have been successfully sent...refreshing</span><br/><br/>
            </div>
            ) : null}
            {!show ? (  
            <div>
            {description && <Description>{description}</Description>}
            {description && <p>{description2}</p>}
            </div>
            ) : null}
            {!show ? ( 
            <Form action={formAction} method={formMethod} onSubmit={sendEmail}>
              <>
              
              <div>
              <label class="checkbox-inline">
                
              <Checkbox placeholder="sliced" name="sliced" onChange={ handleChange }  />&nbsp;&nbsp;I would like my bread sliced</label>
              </div>
              <div className="d-flex">
                <Input type="text" name="name" placeholder="Full Name" required />             
                <Input type="text" name="phone" placeholder="Phone" required />
              </div>              
              <div className="d-flex" style={{ textAlign:"Center", Width: "300px"}}>
                <Input type="email" name="email" placeholder="Email" />
                <Input type="text" name="preferred" placeholder="Preferred Delivery Date" required />
              </div>              
              <InputWide type="text" name="address" placeholder="Delivery Address" required />
              <Input type="text" defaultValue={InitialValues.subject} name="subject" placeholder="Subject" hidden />

              <div className="d-flex">
              <Textarea 
              value={EmailDetailText}
              name="message" placeholder="Order Data" />
              </div>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
              </>
              </Form>
            ) : null}
          </TextContent>
  );
};
