import { Card } from "react-bootstrap";
import { CartState } from "../context/Context";
import React, { useEffect } from "react" ;
import tw from "twin.macro";
import { PrimaryButton as PrimaryButtonBase2 } from "../components/misc/ButtonDanger.js";
import { PrimaryButton as PrimaryButtonBase1 } from "../components/misc/Buttons2.js";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener'

const RemoveButton = tw(PrimaryButtonBase2)`inline-block mt-8`
const AddButton = tw(PrimaryButtonBase1)`inline-block mt-8`
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const SingleProduct = ({ prod }) => {
  const {
    state: { cart },
    dispatch,
  } = CartState();

  return (
    <div className="products">
      <Card>
      <Tooltip disableFocusListener TransitionComponent={Zoom} title={prod.ingredients}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: 'primary',
                        '& .MuiTooltip-arrow': {
                          color: 'common.white',
                        },
                      },
                    },
                  }}>
      <CardImageContainer imageSrc={prod.imageSrc} />
      </Tooltip>
        <Card.Body>
          <Card.Title>{prod.title}</Card.Title>
          <Card.Subtitle style={{ paddingBottom: 10 }}>
            <div></div>
            <span>{prod.content}</span>
            <div  style={{ paddingBottom: 10 }}></div>
            <span style={{ fontWeight: "bold" }}>${prod.price}</span>
            {/* {prod.breakfast ? (
              <div>Breakfast</div>
            ) : (
              <div></div>
            )} */}

          </Card.Subtitle>
          {cart.some((p) => p.id === prod.id) ? (
            <RemoveButton 
              variant="danger"
               style={{ color: "black" }}
               onClick={() =>
               dispatch({
                  type: "REMOVE_FROM_CART",
                  payload: prod,
                })
              }
            >
              Remove from Cart
            </RemoveButton>
          ) : (
            <AddButton
              variant="primary"
              style={{ color: "black" }}
              onClick={() =>
                dispatch({
                  type: "ADD_TO_CART",
                  payload: prod,
                })
              }
              disabled={!prod.inStock}
            >
              {!prod.inStock ? "Out of Stock" : "Add to Cart"}
            </AddButton>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default SingleProduct;






// import { Button } from "react-bootstrap";
// import { CartState } from "../context/Context";
// import { motion } from "framer-motion";
// import tw from "twin.macro";
// import styled from "styled-components";
// import { css } from "styled-components/macro"; //eslint-disable-line
// import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

// const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
// const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
// const CardImageContainer = styled.div`
//   ${props => css`background-image: url("${props.imageSrc}");`}
//   ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
// `;
// const CardHoverOverlay = styled(motion.div)`
//   background-color: rgba(255, 255, 255, 0.5);
//   ${tw`absolute inset-0 flex justify-center items-center`}
// `;
// const CardButton = tw(PrimaryButtonBase)`text-sm`;
// const CardText = tw.div`p-4 text-gray-900`;
// const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
// const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
// const CardPrice = tw.p`mt-4 text-xl font-bold`;

// const SingleProduct = ({ prod }) => {
//   const {
//     state: { cart },
//     dispatch,
//   } = CartState();

//   return (
//     <div className="products">
//               <CardContainer key={prod.id}>
//                 <Card className="group" initial="rest" whileHover="hover" animate="rest">
//                   <CardImageContainer imageSrc={prod.imageSrc}>
//                     <CardHoverOverlay
//                       variants={{
//                         hover: {
//                           opacity: 1,
//                           height: "auto"
//                         },
//                         rest: {
//                           opacity: 0,
//                           height: 0
//                         }
//                       }}
//                       transition={{ duration: 0.3 }}
//                     >
//                       <CardButton>Add To Cart</CardButton>
//                     </CardHoverOverlay>
//                   </CardImageContainer>
//                   <CardText>
//                     <CardTitle>{prod.title}</CardTitle>
//                     <CardContent>{prod.content}</CardContent>
//                     <CardPrice>{prod.price}</CardPrice>
//                   </CardText>
//                   {cart.some((p) => p.id === prod.id) ? (
//             <Button
//               variant="danger"
//               onClick={() =>
//                 dispatch({
//                   type: "REMOVE_FROM_CART",
//                   payload: prod,
//                 })
//               }
//             >
//               Remove from Cart
//             </Button>
//           ) : (
//             <Button
//               onClick={() =>
//                 dispatch({
//                   type: "ADD_TO_CART",
//                   payload: prod,
//                 })
//               }
//               disabled={!prod.active}
//             >
//               {!prod.active ? "Out of Stock" : "Add to Cart"}
//             </Button>
//           )}
//                 </Card>
//               </CardContainer>



//       {/* <Card>
//         <Card.Img variant="top" src={prod.imageSrc} alt={prod.title} />
//         <Card.Body>
//           <Card.Title>{prod.title}</Card.Title>
//           <Card.Subtitle style={{ paddingBottom: 10 }}>
//             <span>₹ {prod.price.split(".")[0]}</span>
//           </Card.Subtitle>
//           {cart.some((p) => p.id === prod.id) ? (
//             <Button
//               variant="danger"
//               onClick={() =>
//                 dispatch({
//                   type: "REMOVE_FROM_CART",
//                   payload: prod,
//                 })
//               }
//             >
//               Remove from Cart
//             </Button>
//           ) : (
//             <Button
//               onClick={() =>
//                 dispatch({
//                   type: "ADD_TO_CART",
//                   payload: prod,
//                 })
//               }
//               disabled={false}
//             >
//               {"Add to Cart"}
//             </Button>
//           )}
//         </Card.Body>
//       </Card> */}
//     </div>
//   );
// };

// export default SingleProduct;
